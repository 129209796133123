import { ethers } from 'ethers';
import ecc from '@bitcoinerlab/secp256k1';
import * as bitcoin from 'bitcoinjs-lib';
import { provider } from 'web3-core';
import BIP32Factory from 'bip32';
import { Buffer } from 'buffer';
import Web3 from 'web3';
import * as Segwit from 'segwit';
import { apiGetAccessToken, apiGetNonceWithAddress, getSignature } from 'src/redux/walletAccount/WalletAccount.service';
import { IRootState } from 'src/redux/interface';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import { ACTION_TYPES as ACTION_TYPES_WALLET } from 'src/components/Header/redux/Wallet.action';
import { ACTION_TYPES, IToken } from './WalletAccount.type';

bitcoin.initEccLib(ecc);
const bip32 = BIP32Factory(ecc);

const toXOnly = (pubKey: Buffer) => (pubKey.length === 32 ? pubKey : pubKey.slice(1, 33));
const TAPR0OT_MESSAGE =
    'This message to create your Bitcoin wallet. You will use them to send and receive NFT as well as BTC with Metamask.';

const defaultPath = "m/86'/0'/0'/0/0";
export const actionUpdateAccessToken = (payload: IToken) => ({
    type: ACTION_TYPES.ACTION_UPDATE_ACCESS_TOKEN,
    payload,
});

export const actionUpdateErrorCode = (statusCode: number) => ({
    type: ACTION_TYPES.ACTION_UPDATE_CODE_ERROR,
    payload: {
        statusCode,
    },
});

export const isChainSupported = async (chainID: any) => {
    const currentChainID = await new Web3(window.ethereum as provider).eth.getChainId();
    return currentChainID;
};

export const getBitcoinKeySignContent = (message: string): Buffer => {
    return Buffer.from(message);
};

export const generateBitcoinKey = async ({
    address,
    message: messageSegwit, // sign message with second sign transaction
}: {
    address: string;
    message: string;
}) => {
    const providerGenerate = new ethers.providers.Web3Provider(window.ethereum as ethers.providers.ExternalProvider);
    const toSign = `0x${getBitcoinKeySignContent(TAPR0OT_MESSAGE).toString('hex')}`;
    const signature = await providerGenerate.send('personal_sign', [toSign, address.toString()]);

    const seed = ethers.utils.arrayify(ethers.utils.keccak256(ethers.utils.arrayify(signature)));

    const root = bip32.fromSeed(Buffer.from(seed));

    // Taproot
    const taprootChild = root.derivePath(defaultPath);
    const { address: sendAddressTaproot } = bitcoin.payments.p2tr({
        internalPubkey: toXOnly(taprootChild.publicKey),
    });

    // Segwit
    const signSegwit = await Segwit.signBitcoinSegwitKey({
        signMessage: messageSegwit,
        root,
    });
    return {
        taproot: {
            sendAddress: sendAddressTaproot,
            signature,
            message: TAPR0OT_MESSAGE,
        },

        segwit: {
            sendAddress: signSegwit.address,
            signature: signSegwit.signature,
            message: signSegwit.message,
            messagePrefix: signSegwit.messagePrefix,
        },
    };
};

let index = -1;

export const actionSignIn = (account: string) => async (dispatch: any, getState: () => IRootState) => {
    try {
        if (index !== -1) return;
        if (account) {
            index += 1;
            const res = await apiGetNonceWithAddress(account);
            const { Address, Nonce } = res as any;
            const { segwit, taproot } = (await generateBitcoinKey({
                address: Address,
                message: `Sign in to Ordmaket\n\nChallenge: ${Nonce}`,
            })) as any;
            const referralCode: any = localStorage.getItem('referral_code');
            const accessToken = await apiGetAccessToken(
                Address,
                segwit.signature.toString('base64'),
                segwit.sendAddress,
                taproot.sendAddress,
                referralCode,
            );
            const newToken: IToken = {
                address: Address.toUpperCase(),
                token: accessToken,
                nonce: Nonce,
                tapRootAddress: taproot.sendAddress,
                segWitAddress: segwit.sendAddress,
                signature: segwit.signature.toString('base64'),
            };
            dispatch(actionUpdateAccessToken(newToken));
            index = -1;
        }
    } catch (error: any) {
        dispatch({
            type: ACTION_TYPES_WALLET.SET_CONNECT_WALLET,
            payload: false,
        });
        console.log('actionSignIn: error', error);
        dispatch(
            addToast({
                type: TOAST_TYPES.ERROR,
                title: 'OOPS!',
                description: error.message,
            }),
        );
    }
};
