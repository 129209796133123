import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core';
import { NetworkContextName } from 'src/app/App.constant';
import { ChainId } from '@sushiswap/sdk';
import { useWallet } from '@solana/wallet-adapter-react';
import { useAptosWallet } from 'src/3rd/aptos';

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & {
    chainId?: ChainId;
} {
    const impersonate = false;
    const context = useWeb3ReactCore<Web3Provider>();
    const { publicKey, disconnect } = useWallet();
    const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName);
    const { accountAptos, disconnectAptos } = useAptosWallet();
    if (publicKey) {
        return { ...context, account: publicKey.toString(), deactivate: disconnect };
    }
    if (accountAptos?.address) {
        return { ...context, account: accountAptos.address.toString(), deactivate: disconnectAptos };
    }
    return context.active
        ? { ...context, account: impersonate || context.account }
        : { ...contextNetwork, account: impersonate || contextNetwork.account };
}

export default useActiveWeb3React;
