export const ACTION_TYPES = {
    SIGNIN: '[auth] action change signin',
    SIGNUP: '[auth] action change signup',
    FORGOT: '[auth] action change forgot',
    SIGNIN_SUCCESS: '[auth] action signin success',
    LOGOUT: '[auth] action logout',
    FREE: '[auth] action free',
    LOADING: '[auth] action loading',
    VERIFY: '[auth] action set verify',
};
