/* eslint-disable camelcase */
import * as GENERATIVE_SDK from 'generative-sdk';

export interface ISendInsProps {
    receiverAddress: string;
    feeRate: number;
    inscriptionNumber: number;
    inscriptionID: string;
}
export interface ISignKeyResp {
    privateKey: Buffer;
    tpAddress: string;
    evmAddress: string;
}

export interface IPagingResponse {
    page: number;
    pageSize: number;
    total: number;
}
export type ProjectSocial = {
    web: string;
    twitter: string;
    discord: string;
    medium?: string;
    etherScan?: string;
    instagram: string;
    twitterVerified?: boolean;
};
export type User = {
    displayName: string;
    bio: string;
    avatar: string;
    walletAddress: string;
    id: string;
    createdAt: string;
    profileSocial?: ProjectSocial;
    bgCover?: string;
    walletAddressBtc?: string;
    walletAddressBtcTaproot?: string;
    projects?: { name: string; projectId: string }[];
    walletAddressPayment?: string;
};
export type TokenAttribute = {
    trait_type: string;
    value: string;
};

export interface MarketplaceStats {
    bestMakeOfferPrice: string;
    floorPrice: string;
    listedPercent: number;
    totalTradingVolumn: string;
    uniqueOwnerCount: number;
}

export type TraitStats = {
    traitName: string;
    traitValuesStat: {
        value: string;
        rarity: number;
    }[];
};
export type Project = {
    id: string;
    maxSupply: number;
    limit: number;
    mintPrice: string;
    mintPriceAddr: string;
    mintPriceEth: string;
    name: string;
    creator: string;
    creatorAddr: string;
    license: string;
    desc: string;
    image: string;
    scriptType: string[];
    social: ProjectSocial | null;
    scripts: string[];
    styles: string;
    completeTime: number;
    genNFTAddr: string;
    itemDesc: string;
    status: boolean;
    nftTokenURI: string;
    projectURI: string;
    royalty?: number;
    tokenID: string;
    mintingInfo: {
        index: number;
        indexReserve: number;
    };
    creatorProfile?: User;
    mintedTime: number;
    stats: MarketplaceStats;
    traitStat: TraitStats[];
    closeMintUnixTimestamp?: number;
    openMintUnixTimestamp?: number;
    whiteListEthContracts?: string[];
    isFullChain: boolean;
    isHidden: boolean;
    networkFee?: string;
    networkFeeEth?: string;
    totalImages?: number;
    categories?: string[];
    btcFloorPrice: number; // satoshi number
    reportUsers?: {
        originalLink?: string;
        reportUserAddress: string;
    }[];
    animationHtml: string;
    totalVolume: string;
};

export type Token = {
    thumbnail?: string;
    name: string;
    description: string;
    image: string;
    animationUrl: string;
    animation_url: string;
    attributes: Array<TokenAttribute>;
    genNFTAddr: string;
    owner: User;
    project: Project;
    mintedTime: string;
    creatorProfile?: User;
    ownerAddr: string;
    creator: User;
    tokenID: string;
    inscriptionIndex: string;
    buyable: boolean;
    isCompleted: boolean;
    priceBTC: string;
    orderID: string;
    projectID?: string;
    projectName?: string;
    stats: {
        price: string;
    };
    animationHtml: string;
    listingDetail?: {
        paymentListingInfo: {
            btc: {
                paymentAddress: string;
                price: string;
            };
            eth?: {
                paymentAddress: string;
                price: string;
            };
        };
    };

    ordinalsData?: {
        sat: string;
        contentLength: string;
        contentType: string;
        timeStamp: string;
        block: string;
    };
};

export interface IGetProfileTokensResponse {
    result: Array<Token>;
    total: number;
    page: number;
}
export interface IGetProjectItemsResponse extends IPagingResponse {
    result: Project[];
}

export interface ICollectedUTXOResp {
    address: string;
    inscription_id: string;
    balance: number;
    unconfirmed_balance: number;
    final_balance: number;
    txrefs: GENERATIVE_SDK.UTXO[];
    inscriptions_by_outputs: IInscriptionByOutput;
}
export interface IInscriptionByOutputValue {
    offset: number;
    id: string;
}
export interface IInscriptionByOutput {
    [key: string]: IInscriptionByOutputValue[];
}

export enum FeeRateName {
    fastestFee = 'fastestFee',
    halfHourFee = 'halfHourFee',
    hourFee = 'hourFee',
}

export interface IFeeRate {
    [FeeRateName.fastestFee]: number;
    [FeeRateName.halfHourFee]: number;
    [FeeRateName.hourFee]: number;
}

export interface ISendBTCProps {
    receiverAddress: string;
    feeRate: number;
    amount: number;
}

export interface IError {
    message: string;
    code: number;
}
export enum TrackTxType {
    normal = 'normal',
    inscription = 'inscription',
    buyInscription = 'buy-inscription',
    buySplit = 'buy-split-inscription',
    listSplit = 'list-split-inscription',
    list = 'list-inscription',
    cancel = 'cancel-list-inscription',
}

export interface ITrackTx {
    address: string;
    receiver: string;
    type: TrackTxType;
    inscription_id: string;
    inscription_number: number;
    send_amount: number;
    txhash: string;
}
