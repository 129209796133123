import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/profile/edit/:id';

const EditAccountsRoute: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('./EditAccounts')),
    name: 'EditAccounts',
};

export default EditAccountsRoute;
