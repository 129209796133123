import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Loading } from 'src/components/LoadingSearch';
import useOnClickOutside from 'src/hooks/useOnClickOutSide';
import { ellipsisRight } from 'src/utils/ellipsis';
import cs from 'classnames';
import { debounce } from 'lodash';
import Image from 'next/image';
import { Typography } from 'antd';
import { v4 } from 'uuid';
import { ReactComponent as SearchSVG } from 'src/search-icon.svg';
import LoadingGif from 'src/loading.gif';
import { apiSearchCollections } from 'src/modules/Collections/redux/Collections.service';
import s from './styles.module.scss';

const { Text } = Typography;
const SearchCollection = () => {
    const [foundCollections, setFoundCollections] = useState<any[]>();
    const [searchText, setSearchText] = useState<string>('');
    const [showResult, setShowResult] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const inputSearchRef = useRef<HTMLInputElement>(null);
    const resultSearchRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleSearch = async () => {
        try {
            setIsLoading(true);
            const collections: any = await apiSearchCollections(searchText);

            if (collections && collections?.Items) {
                setFoundCollections(collections?.Items);
            }
            setIsLoading(false);
        } catch (err: unknown) {
            console.log('Failed to fetch collections', err);
            throw Error();
        }
    };

    const handleCloseSearchResult = (): void => {
        setShowResult(false);
    };

    useEffect(() => {
        if (searchText && searchText.length > 2) {
            handleSearch();
        }
    }, [searchText]);

    useOnClickOutside(resultSearchRef, () => handleCloseSearchResult());

    const onClose = (collectionSeoName: any) => {
        window.open(`/collection/${collectionSeoName}`, '_self');
    };
    const SearchCollectionItem = ({ item }: { item?: any }) => {
        return (
            <NavLink
                className={s.list_ResultSearch}
                to={`/collection/${item?.CollectionSeoName}`}
                onClick={() => onClose(item?.CollectionSeoName)}
            >
                <div className={s.searchResult_collectionThumbnail}>
                    <img width={34} height={34} alt="" src={item?.Image} />
                </div>
                <div className={s.searchResult_collectionInfo}>
                    <Text className={s.searchResult_collectionName}>{item?.CollectionName}</Text>
                    <Text className={s.searchResult_creatorName}>
                        <div
                            style={{ display: 'flex' }}
                            dangerouslySetInnerHTML={{
                                __html: ellipsisRight({
                                    str: item?.Description,
                                    limit: 35,
                                }),
                            }}
                        />
                    </Text>
                </div>
            </NavLink>
        );
    };
    const SearchCollectionsResult = ({ list }: { list: any[] }) => {
        if (list.length === 0) return null;
        return (
            <>
                <div className={s.list_heading}>
                    <Text>COLLECTIONS</Text>
                </div>
                {list.map((collection) => (
                    <SearchCollectionItem key={`collection-${v4()}`} item={collection} />
                ))}
            </>
        );
    };

    return (
        <div id="style-wrapper" className={`${s.wrapper}`} ref={wrapperRef}>
            <div id="wrap-header-search" className={cs(s.searchInput_wrapper)}>
                <input
                    className={`search-main ${s.input}`}
                    placeholder="Search Collections or Inscription ID"
                    onChange={debounce((e) => {
                        setSearchText(e.target.value);
                        setShowResult(true);
                    }, 300)}
                    onFocus={(e) => {
                        setSearchText(e.target.value);
                        setShowResult(true);
                    }}
                    ref={inputSearchRef}
                />
                <div className={s.searchIcon}>
                    {!isLoading ? (
                        <SearchSVG />
                    ) : (
                        <img src={LoadingGif} style={{ marginBottom: 5 }} width={24} height={24} alt="" />
                    )}
                </div>
            </div>
            {!isLoading && showResult && searchText && searchText.length > 2 && (
                <div id="search-result" className={s.searchResult_wrapper} ref={resultSearchRef}>
                    {foundCollections && <SearchCollectionsResult list={foundCollections} />}
                    {foundCollections?.length === 0 && <div className={s.searchResult_item}>No Result Found</div>}
                </div>
            )}
        </div>
    );
};
export default SearchCollection;
