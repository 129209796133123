import { BigNumber } from '@ethersproject/bignumber';
import { getAddress } from '@ethersproject/address';
import { validate, getAddressInfo, AddressType } from 'bitcoin-address-validation';
import { ethers } from 'ethers';
/**
 * Returns true if the string value is zero in hex
 * @param hexNumberString
 */
export function isZero(hexNumberString: string): boolean {
    return /^0x0*$/.test(hexNumberString);
}

export const isEmptyValue = (text: string) =>
    BigNumber.isBigNumber(text)
        ? BigNumber.from(text).isZero()
        : text === '' || text.replace(/0/g, '').replace(/\./, '') === '';

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
    try {
        return getAddress(value);
    } catch {
        return false;
    }
}
export const validateWalletAddress = (address: string): boolean => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
};

export const validateBTCAddressTaproot = (_address: string): boolean => {
    const isBTCAddress = validate(_address);
    if (isBTCAddress) {
        const addressInfo = getAddressInfo(_address);
        return addressInfo.type === AddressType.p2tr;
    }
    return false;
};

export const validateBTCAddress = (_address: string): boolean => {
    return validate(_address);
};

export const validateEVMAddress = (_address: string): boolean => {
    return ethers.utils.isAddress(_address);
};
