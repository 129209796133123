import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/current-minting';

const CurrentMintingRoute: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/CurrentMinting')),
    name: 'CurrentMinting',
};

export default CurrentMintingRoute;
