import React, { FC, HTMLProps, useCallback } from 'react';
import { classNames } from 'src/functions';
import styled, { ITheme } from 'styled-components';

interface ExternalLinkProps extends Omit<HTMLProps<HTMLAnchorElement>, 'as' | 'ref' | 'onClick'> {
    href?: string;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
}

const Wrapper = styled.a`
    color: ${({ theme }: { theme: ITheme }) => theme.text3};
    cursor: pointer;
    &:hover {
        color: ${({ theme }: { theme: ITheme }) => theme.text5};
    }
`;

const ExternalLink: FC<ExternalLinkProps> = ({
    target = '_blank',
    href,
    children,
    rel = 'noopener noreferrer',
    className = '',
    startIcon = undefined,
    endIcon = undefined,
    ...rest
}) => {
    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            // don't prevent default, don't redirect if it's a new tab
            event.preventDefault();
            if (!href) return;
            window.open(href, '_blank');
        },
        [href, target],
    );

    return (
        <Wrapper
            target={target}
            rel={rel}
            href={href}
            onClick={handleClick}
            className={classNames('items-center', className)}
            {...rest}
        >
            {startIcon && startIcon}
            {children}
            {endIcon && endIcon}
        </Wrapper>
    );
};

export default ExternalLink;
