import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/project/:id';

const CurrentMintingRoute: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/MintingDetail')),
    name: 'MintingDetail',
};

export default CurrentMintingRoute;
