import React, { memo, useEffect, useState, useContext } from 'react';
import styled, { ITheme } from 'styled-components';
import { COMPONENT_IDS } from 'src/app/App.constant';
import { MenuOutlined } from '@ant-design/icons';
import withConnect from 'src/hoc/withConnect';
import { injected } from 'src/configs/Connect/Configs.wallets';
import { ReactComponent as LogoSVG } from 'src/logo.svg';
import { ReactComponent as LogoMobileSVG } from 'src/logo-mobile.svg';
import { ellipsisCenter } from 'src/utils/ellipsis';
import { ReactComponent as IcClose } from 'src/assets/svg/ic_close_drawer.svg';
import metaMaskIco from 'src/ic-metamask.png';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { formatBTCPrice } from 'src/utils/format';
import { useBitcoin } from 'src/hooks/useBitcoin';
import SearchCollection from 'src/components/SearchCollection';
import { Button, Drawer, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { SUPPORTED_NETWORKS } from 'src/configs/Connect/Configs.networks';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { ChainId } from 'src/configs/Connect/Configs.chainId';
import { ProfileProvider } from 'src/components/Provider/profile-context';
import { ReactComponent as BtcIconSVG } from 'src/btc.svg';
import { ACTION_TYPES } from 'src/components/Header/redux/Wallet.action';
import { HeaderFrame } from './Header.styled';
import MenuMobileV2 from './components/MenuMobileV2';

const { Search } = Input;
interface IHeaderProps {
    openLoginModal: () => void;
    tryActivation: (connector: any) => void;
    changeChainId: (chainId: number) => void;
}

const ButtonConnect = styled.button`
    width: 215px !important;
    height: 40px !important;
    background: #6b83ff !important;
    display: flex;
    margin-right: 12px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-right: 10px;
    .logo-wallet {
        margin-left: 10px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
    img {
        width: 25px;
        margin-right: 10px;
    }
    p {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #fff;
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        margin-right: 0;
        justify-content: center;
        width: 100% !important;
        margin-bottom: 25px;
        padding-right: 0px;
        .logo {
           padding-right: 10px;
        }
    `}
`;

const BTNWallet = styled.button`
    width: fit-content !important;
    height: 40px !important;
    // margin-right: 12px;
    background: transparent !important;
    border: 1px solid ${({ theme }: { theme: ITheme }) => theme.border2} !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 2px;
    padding-left: 12px;
    svg {
        width: 20px;
        margin-right: 8px;
    }
    .logo {
        margin-left: 16px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
    .addressText {
        // background-color: ${({ theme }: { theme: ITheme }) => theme.dark7};
        color: ${({ theme }: { theme: ITheme }) => theme.text2};
        line-height: 32px;
        // border-radius: 16px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 10px;
        display: flex;
    }

    .balanceText {
        color: ${({ theme }: { theme: ITheme }) => theme.green1};
        width: max-content;
        span {
            color: ${({ theme }: { theme: ITheme }) => theme.text2};
            margin-left: 8px;
        }
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        margin-right: 0;
        width: 100% !important;
        margin-bottom: 25px;
    `}
`;

interface IBTNProps {
    address?: string;
    setChainId?: any;
    isMobile?: boolean;
}

const BTNWalletInfo = React.memo(({ address }: { address: any }) => {
    const wallet = useSelector((state: any) => state.walletAccount);
    const history = useHistory();
    const { satoshiAmount } = useBitcoin();
    const showBalance = () => {
        return `${satoshiAmount / 1e8} BTC`;
    };
    return (
        <BTNWallet
            className="btn-connect-wallet btn-primary"
            onClick={() => {
                history.push(`/profile/${wallet?.tapRootAddress}`);
            }}
        >
            <p className="fw-suppermedium balanceText">
                {showBalance()} <span>|</span>
            </p>
            <p className="fw-regular addressText">
                <BtcIconSVG />
                {ellipsisCenter({ str: wallet?.tapRootAddress || '', limit: 6 })}
            </p>
        </BTNWallet>
    );
});

const locationPaths = ['/projects/create'];

function Header({ openLoginModal, changeChainId, tryActivation }: IHeaderProps & any) {
    const [visible, setVisible] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [currentPath, setCurrentPath] = useState({
        pathname: '',
        search: '',
    });
    const { library, account, chainId } = useActiveWeb3React();
    const wallet = useSelector((state: any) => state.wallet);
    const walletAccount = useSelector((state: any) => state.walletAccount);
    const dispatch = useDispatch();
    const history = useHistory();
    history.listen((location) => {
        const { pathname, search } = location;
        setCurrentPath((pre) => {
            if (pre.pathname === pathname && search === pre.search) return pre;
            return {
                pathname,
                search,
            };
        });
        // location is an object like window.location
    });
    let { pageYOffset } = window;
    window.addEventListener('scroll', () => {
        pageYOffset = window.pageYOffset;
        if (pageYOffset > 20) {
            if (!scroll) {
                setScroll(true);
            }
        } else {
            setScroll(false);
        }
    });
    const openMenu = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const isInstalledMetamask = () => {
        if (typeof window.ethereum !== undefined && !window.ethereum?.isMetaMask) {
            window.open('https://metamask.io/download/');
            return false;
        }
    };
    function connectWallet() {
        isInstalledMetamask();
        localStorage.setItem('connector', 'injected');
        tryActivation(injected);
        dispatch({
            type: ACTION_TYPES.SET_CHAIN_ID,
            payload: null,
        });
    }

    useEffect(() => {
        setCurrentPath(history.location);
    }, []);
    useEffect(() => {
        if (account && wallet.chainId && chainId !== wallet.chainId) {
            if (chainId !== ChainId.MAINNET) {
                library?.send('wallet_switchEthereumChain', [{ chainId: '0x1' }, account]);
            } else {
                const params = cloneDeep(SUPPORTED_NETWORKS[wallet.chainId]);
                delete params?.chainNumb;
                delete params?.path;
                delete params?.pathDisable;
                library?.send('wallet_addEthereumChain', [params, account]);
            }
        }
    }, [wallet.chainId, account]);
    return (
        <>
            {/* <TopHeader /> */}
            <HeaderFrame
                id={COMPONENT_IDS.HEADER}
                style={{
                    top: '0',
                    background: scroll ? '#0A0909' : 'transparent',
                    transition: '1s',
                }}
            >
                <div className="menu-desktop">
                    {/* <DrawerMenu /> */}
                    <div className="logo">
                        <NavLink to="/">
                            <LogoSVG />
                        </NavLink>
                    </div>
                    <div className="fs-small wrap-search">
                        <SearchCollection />
                    </div>

                    {/* <div className="drawer-menu">
                        <div onClick={openMenuHeaderPC}>
                            <DrawerMenuSvg />
                            <span className="fs-regular fw-suppermedium">MENU</span>
                        </div>
                        <Drawer
                            placement="left"
                            width="40%"
                            closable
                            keyboard
                            visible={visibleMenu}
                            key="left"
                            onClose={onCloseMenuHeaderPC}
                            className="drawer-header-menu"
                            drawerStyle={{ backgroundColor: '#141416' }}
                            closeIcon={<IcClose style={{ marginTop: 8 }} width={14} height={14} />}
                        >
                        </Drawer>
                    </div> */}
                    {/* <div className="logo">
                        <NavLink to="/">
                            ordmarket.io
                        </NavLink>
                    </div> */}
                    <div className="right-header">
                        <div className="position-right-header">
                            <div className="network">
                                <div className="box-left">
                                    <div className="list-nav">
                                        <Link
                                            className={`item-nav fs-regular fw-regular menu-top-page create-collection-menu ${
                                                currentPath.pathname === '/projects/create' ? 'active' : ''
                                            }`}
                                            to="/projects/create"
                                        >
                                            Create Project
                                        </Link>
                                        <Link
                                            className={`item-nav fs-regular fw-regular menu-top-page earn-menu ${
                                                currentPath.pathname === '/current-minting' ? 'active' : ''
                                            }`}
                                            to="/current-minting"
                                        >
                                            Live
                                        </Link>
                                        <Link
                                            className={`item-nav fs-regular fw-regular menu-top-page earn-menu ${
                                                currentPath.pathname === '/airdrop' ? 'active' : ''
                                            }`}
                                            to="/airdrop"
                                        >
                                            Airdrop
                                        </Link>
                                        {walletAccount?.tapRootAddress && account && (
                                            <Link
                                                className={`item-nav fs-regular fw-regular menu-top-page earn-menu ${
                                                    currentPath.pathname.includes('/profile/') &&
                                                    currentPath.search.includes('?tab=referral')
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                to={`/profile/${walletAccount?.tapRootAddress}?tab=referral`}
                                            >
                                                Earn
                                            </Link>
                                        )}
                                        <a
                                            className="item-nav fs-regular fw-regular menu-top-page earn-menu"
                                            href="https://docs.ordmarket.io/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Wiki
                                        </a>
                                    </div>
                                </div>
                                {/* <Button
                                    className="btn-join-wait-list fw-bold btn-primary"
                                    onClick={() => {
                                        history.push('/join-wait-list');
                                    }}
                                >
                                    Join Waitlist
                                </Button> */}
                                {/* <DropdownNetwork /> */}
                                {walletAccount?.tapRootAddress && account ? (
                                    <BTNWalletInfo address={account} />
                                ) : (
                                    <ButtonConnect className="fw-bold btn-primary" onClick={() => connectWallet()}>
                                        <p>
                                            {' '}
                                            <img alt="" src={metaMaskIco} />
                                            Login via MetaMask
                                        </p>
                                    </ButtonConnect>
                                )}
                                {/* {account && <DropdownAccount />} */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu-mobile">
                    <div className="logo">
                        <NavLink to="/">
                            <LogoMobileSVG />
                        </NavLink>
                    </div>
                    <div className="fs-small wrap-search">
                        <SearchCollection />
                    </div>
                    <div className="connect-mobile">
                        {/* <DropdownNetwork /> */}
                        <Button
                            className="btn-menu-mobile fs-regular fw-medium"
                            shape="round"
                            size="large"
                            style={{ color: 'white' }}
                            onClick={openMenu}
                        >
                            <MenuOutlined />
                        </Button>
                    </div>

                    <Drawer
                        placement="left"
                        width="100%"
                        closable
                        visible={visible}
                        key="left"
                        onClose={onClose}
                        className="drawer-header-mobile"
                        drawerStyle={{ backgroundColor: '#141416' }}
                        closeIcon={<IcClose style={{ marginTop: 8 }} width={20} height={20} />}
                    >
                        <MenuMobileV2 onClose={onClose} />
                    </Drawer>
                </div>
            </HeaderFrame>
        </>
    );
}
export default withConnect(memo(Header));
