import React, { useCallback, useMemo } from 'react';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';
import {
    getPhantomWallet,
    getSolflareWallet,
    getSolletExtensionWallet,
    getCoin98Wallet,
    getSlopeWallet,
} from '@solana/wallet-adapter-wallets';
import { WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-ant-design';
import { isMainnet } from 'src/configs/Configs.env';
import { useDispatch } from 'react-redux';
import { addToast } from '../Toast/redux/Toast.service';
import { TOAST_TYPES } from '../Toast/redux/Toast.action';

function SolanaAdapterProvider({ children }: { children: any }) {
    const network = isMainnet ? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet;
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    const dispatch = useDispatch();
    const wallets = useMemo(
        () => [
            getPhantomWallet(),
            getSolflareWallet(),
            getCoin98Wallet(),
            getSolletExtensionWallet(),
            getSlopeWallet(),
        ],
        [network],
    );

    const onError = useCallback((error: WalletError) => {
        dispatch(
            addToast({
                type: TOAST_TYPES.ERROR,
                title: 'Error!',
                description: error.message ? `${error.name}: ${error.message}` : error.name,
            }),
        );
    }, []);

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} onError={onError} autoConnect>
                <WalletModalProvider className="model-solana-custom">{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
}

export default SolanaAdapterProvider;
