import { TransactionPayload, HexEncodedBytes } from 'aptos/src/generated/index';
import { createContext, useContext } from 'react';
import { AccountKeys, WalletAdapter, WalletName, WalletReadyState } from '../WalletAdapters/BaseAdapter';

export interface Wallet {
    adapter: WalletAdapter;
    readyState: WalletReadyState;
}

export interface WalletContextState {
    autoConnect: boolean;
    wallets: Wallet[];
    wallet: Wallet | null;
    accountAptos: AccountKeys | null;
    connecting: boolean;
    connected: boolean;
    disconnecting: boolean;
    select(walletName: WalletName): void;
    connectAptos(walletName: string): Promise<void>;
    disconnectAptos(): Promise<void>;
    signAndSubmitTransactionAptos(transaction: TransactionPayload, options?: any): Promise<{ hash: HexEncodedBytes }>;
    signTransactionAptos(transaction: TransactionPayload, options?: any): Promise<Uint8Array>;
    signMessageAptos(message: string): Promise<string>;
}

const DEFAULT_CONTEXT = {
    autoConnect: false,
    connecting: false,
    connected: false,
    disconnecting: false,
} as WalletContextState;

export const WalletContext = createContext<WalletContextState>(DEFAULT_CONTEXT as WalletContextState);

export function useAptosWallet(): WalletContextState {
    return useContext(WalletContext);
}
