/* eslint-disable @typescript-eslint/no-shadow */
import {
    ICollectedUTXOResp,
    IFeeRate,
    ITxHistory,
    ITxHistoryBuyInsETH,
    ITxHistoryPurchase,
} from 'src/interfaces/api/bitcoin';
import { getCollectedUTXO, getFeeRate, getHistory, getPendingUTXOs } from 'src/services/bitcoin';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import log from 'src/utils/logger';
import { LogLevel } from 'src/enums/log-level';
import { useRouter } from 'next/router';
import { validateEVMAddress } from 'src/utils/validate';
import { currentAssetsBuilder, comingAmountBuilder } from 'src/utils/utxo';
import { useSelector } from 'react-redux';

const LOG_PREFIX = 'ASSETS_CONTEXT';

export interface IAssetsContext {
    currentAssets: ICollectedUTXOResp | undefined;
    assets: ICollectedUTXOResp | undefined;
    isLoadingAssets: boolean;
    isLoadedAssets: boolean;

    history: ITxHistory[];
    txsETH: ITxHistoryBuyInsETH[];
    isLoadingHistory: boolean;
    isLoadedHistory: boolean;

    feeRate: IFeeRate | undefined;

    comingAmount: number;

    fetchAssets: () => void;
    fetchHistory: () => void;
    debounceFetchData: () => void;
    fetchFeeRate: () => Promise<IFeeRate | undefined>;

    getAvailableAssetsCreateTx: () => Promise<ICollectedUTXOResp | undefined>;
}

const initialValue: IAssetsContext = {
    currentAssets: undefined,
    assets: undefined,
    isLoadingAssets: false,
    isLoadedAssets: false,

    history: [],
    txsETH: [],
    isLoadingHistory: false,
    isLoadedHistory: false,

    feeRate: undefined,
    comingAmount: 0,

    fetchAssets: () => new Promise<void>((r) => r()),
    fetchHistory: () => new Promise<void>((r) => r()),
    debounceFetchData: () => new Promise<void>((r) => r()),
    fetchFeeRate: () => new Promise<IFeeRate | undefined>(() => null),
    getAvailableAssetsCreateTx: () => new Promise<ICollectedUTXOResp | undefined>(() => null),
};

export const AssetsContext = React.createContext<IAssetsContext>(initialValue);

export const AssetsProvider = ({ children }: any): React.ReactElement => {
    const { tapRootAddress } = useSelector((state: any) => state.walletAccount);
    const currentAddress = tapRootAddress;

    // UTXOs
    const [assets, setAssets] = useState<ICollectedUTXOResp | undefined>();
    const [currentAssets, setCurrentAssets] = useState<ICollectedUTXOResp | undefined>();
    const [isLoadingAssets, setIsLoadingAssets] = useState<boolean>(false);
    const [isLoadedAssets, setIsLoadedAssets] = useState<boolean>(false);

    // History
    const [history, setHistory] = useState<ITxHistory[]>([]);
    const [txsETH, setTxsETH] = useState<ITxHistoryBuyInsETH[]>([]);
    const [isLoadingHistory, setIsLoadingHistory] = useState<boolean>(false);
    const [isLoadedHistory, setIsLoadedHistory] = useState<boolean>(false);

    // Fee rate
    const [feeRate, setFeeRate] = useState<IFeeRate | undefined>();

    const [comingAmount, setcomingAmount] = useState<number>(0);

    // const isOwner = React.useMemo(() => {
    //     if (!walletAddress || validateEVMAddress(walletAddress)) return true;
    //     return !!user && user?.walletAddressBtcTaproot === walletAddress;
    // }, [walletAddress, user]);

    const fetchAssets = async (): Promise<ICollectedUTXOResp | undefined> => {
        if (!currentAddress) return undefined;
        let _assets;
        try {
            setIsLoadingAssets(true);
            _assets = await getCollectedUTXO(currentAddress);
            setAssets(_assets);
        } catch (err) {
            console.log('err: ', err);
            // const error = getError(err);
            // log(error.message, LogLevel.ERROR, LOG_PREFIX);
        } finally {
            setIsLoadingAssets(false);
            setIsLoadedAssets(true);
        }
        return _assets;
    };

    const fetchHistory = async (): Promise<ITxHistory[]> => {
        if (!currentAddress) {
            setHistory([]);
            return [];
        }
        let _history: ITxHistory[] = [];
        try {
            setIsLoadingHistory(true);
            const { txs } = await getHistory(currentAddress);
            _history = txs;
            setHistory(_history);
            setTxsETH(txsETH);
            // setTxsPurchase(txsPurchase);
        } catch (err) {
            console.log('err: ', err);
            // const error = getError(err);
            // log(er, LogLevel.ERROR, LOG_PREFIX);
        } finally {
            setIsLoadingHistory(false);
            setIsLoadedHistory(true);
        }
        return _history;
    };

    const fetchData = async () => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const [assets, pendingUTXOs, _] = await Promise.all([
            await fetchAssets(),
            await getPendingUTXOs(currentAddress),
            await fetchHistory(),
        ]);

        // Current assets
        let _currentAssets;
        if (assets) {
            _currentAssets = currentAssetsBuilder({
                current: assets,
                pending: pendingUTXOs,
            });
        }
        setCurrentAssets(_currentAssets);

        // Coming amount...
        const _comingAmount = comingAmountBuilder(currentAddress, pendingUTXOs);
        setcomingAmount(_comingAmount);
    };

    const debounceFetchData = React.useCallback(debounce(fetchData, 300), [currentAddress]);

    const fetchFeeRate = async () => {
        let _feeRate = {
            fastestFee: 15,
            halfHourFee: 10,
            hourFee: 5,
        };
        try {
            _feeRate = await getFeeRate();
            setFeeRate(_feeRate);
        } catch (error) {
            setFeeRate(_feeRate);
        }
        return _feeRate;
    };

    const getAvailableAssetsCreateTx = async () => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const [assets, pendingUTXOs] = await Promise.all([await fetchAssets(), await getPendingUTXOs(currentAddress)]);

        // Current assets
        let _currentAssets;
        if (assets) {
            _currentAssets = currentAssetsBuilder({
                current: assets,
                pending: pendingUTXOs,
            });
        }
        setCurrentAssets(_currentAssets);

        return _currentAssets;
    };

    useEffect(() => {
        if (currentAddress) {
            debounceFetchData();
        } else {
            setHistory([]);
        }
    }, [currentAddress]);

    useEffect(() => {
        fetchFeeRate().then().catch();
        const intervalID = setInterval(() => {
            fetchFeeRate().then().catch();
        }, 60 * 2 * 1000); // 2 mins
        return () => {
            clearInterval(intervalID);
        };
    }, []);

    const contextValues = useMemo((): IAssetsContext => {
        return {
            currentAssets,
            assets,
            isLoadingAssets,
            isLoadedAssets,

            history,
            txsETH,
            isLoadingHistory,
            isLoadedHistory,

            feeRate,

            comingAmount,

            fetchAssets,
            fetchHistory,
            fetchFeeRate,
            getAvailableAssetsCreateTx,

            debounceFetchData,
        };
    }, [
        currentAssets,
        assets,
        isLoadingAssets,
        isLoadedAssets,

        history,
        txsETH,
        isLoadingHistory,
        isLoadedHistory,

        feeRate,

        comingAmount,

        currentAddress,
    ]);

    return <AssetsContext.Provider value={contextValues}>{children}</AssetsContext.Provider>;
};
