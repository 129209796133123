/* eslint-disable import/no-cycle */
import React from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { injected } from 'src/configs/Connect/Configs.wallets';
import styled, { ITheme } from 'styled-components';
import { ReactComponent as BtcIconSVG } from 'src/btc.svg';
import { ReactComponent as LogoSVG } from 'src/logo.svg';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import metaMaskIco from 'src/ic-metamask.png';
import { ellipsisCenter } from 'src/utils/ellipsis';
import withConnect from 'src/hoc/withConnect';
import { Wrapper } from './MenuMobileV2.styled';
// import { BTNWalletInfo, ButtonConnect } from '../Header';
import { ACTION_TYPES } from '../redux/Wallet.action';

interface IHeaderProps {
    onClose: () => void;
    tryActivation: (connector: any) => void;
}
const BTNWallet = styled.button`
    width: fit-content !important;
    height: 40px !important;
    // margin-right: 12px;
    background: transparent !important;
    border: 1px solid ${({ theme }: { theme: ITheme }) => theme.border2} !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 2px;
    padding-left: 12px;
    svg {
        width: 20px;
        margin-right: 8px;
    }
    .logo {
        margin-left: 16px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
    .addressText {
        // background-color: ${({ theme }: { theme: ITheme }) => theme.dark7};
        color: ${({ theme }: { theme: ITheme }) => theme.text2};
        line-height: 32px;
        // border-radius: 16px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 10px;
        display: flex;
    }

    .balanceText {
        color: ${({ theme }: { theme: ITheme }) => theme.green1};
        width: max-content;
        span {
            color: ${({ theme }: { theme: ITheme }) => theme.text2};
            margin-left: 8px;
        }
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        margin-right: 0;
        width: 100% !important;
        margin-bottom: 25px;
    `}
`;
const BTNWalletInfo = React.memo(({ address }: { address: any }) => {
    const wallet = useSelector((state: any) => state.walletAccount);
    const history = useHistory();
    const showBalance = () => {
        return '0 BTC';
    };
    console.log(address);
    return (
        <BTNWallet
            className="btn-connect-wallet btn-primary"
            onClick={() => {
                history.push(`/profile/${wallet?.tapRootAddress}`);
            }}
        >
            <p className="fw-suppermedium balanceText">
                {showBalance()} <span>|</span>
            </p>
            <p className="fw-regular addressText">
                <BtcIconSVG />
                {ellipsisCenter({ str: wallet?.tapRootAddress || '', limit: 6 })}
            </p>
        </BTNWallet>
    );
});

const ButtonConnect = styled.button`
    width: 215px !important;
    height: 40px !important;
    background: #6b83ff !important;
    display: flex;
    margin-right: 12px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-right: 10px;
    .logo-wallet {
        margin-left: 10px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
    img {
        width: 25px;
        margin-right: 10px;
    }
    p {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #fff;
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        margin-right: 0;
        justify-content: center;
        width: 100% !important;
        margin-bottom: 25px;
        padding-right: 0px;
        .logo {
           padding-right: 10px;
        }
    `}
`;

const MenuMobileV2 = ({ onClose, tryActivation }: IHeaderProps & any) => {
    const history = useHistory();
    const walletAccount = useSelector((state: any) => state.walletAccount);
    const { account } = useActiveWeb3React();
    const { pathname } = history.location;
    const dispatch = useDispatch();

    function connectWallet() {
        localStorage.setItem('connector', 'injected');
        tryActivation(injected);
        dispatch({
            type: ACTION_TYPES.SET_CHAIN_ID,
            payload: null,
        });
    }
    return (
        <Wrapper>
            <NavLink className="logo-mobile" to="/" onClick={onClose}>
                <LogoSVG />
            </NavLink>
            <div className="list-nav">
                <Link
                    onClick={onClose}
                    className={`item-nav ${pathname === '/projects/create' ? 'active' : ''}`}
                    to="/projects/create"
                >
                    Create Project
                </Link>
                <Link
                    onClick={onClose}
                    className={`item-nav ${pathname === '/current-minting' ? 'active' : ''}`}
                    to="/current-minting"
                >
                    Live
                </Link>
                <Link
                    onClick={onClose}
                    className="item-nav fs-regular fw-regular menu-top-page earn-menu"
                    to="/airdrop"
                >
                    Airdrop
                </Link>
                {walletAccount?.tapRootAddress && account && (
                    <Link
                        className="item-nav fs-regular fw-regular menu-top-page earn-menu"
                        to={`/profile/${walletAccount?.tapRootAddress}?tab=referral`}
                    >
                        Earn
                    </Link>
                )}
                <a
                    className="item-nav fs-regular fw-regular menu-top-page earn-menu"
                    href="https://docs.ordmarket.io/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Wiki
                </a>
            </div>
            <div className="box-connect" onClick={onClose}>
                {walletAccount?.tapRootAddress && account ? (
                    <BTNWalletInfo address={account} />
                ) : (
                    <ButtonConnect className="fw-bold btn-primary" onClick={() => connectWallet()}>
                        <p>
                            {' '}
                            <img alt="" src={metaMaskIco} />
                            Login via MetaMask
                        </p>
                    </ButtonConnect>
                )}
            </div>
        </Wrapper>
    );
};

export default withConnect(MenuMobileV2);
