import styled, { ITheme } from 'styled-components';

export const Wrapper = styled.div`
    background: none;
    padding: 12px;
    position: relative;
    height: 100vh;
    .ant-drawer-body {
        padding: 0;
    }

    .logo-mobile {
        font-style: normal;
        font-weight: 500;
        font-size: 1.8em;
        letter-spacing: -0.01em;
        color: #fff;
        font-family: 'Orbitron';
    }

    .list-nav {
        display: flex;
        flex-direction: column;
        gap: 24px;
        // margin-top: 16px;
        padding-top: 24px;
        border-top: 1px solid #c5c8cc;
        .item-nav {
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #c5c8cc;
            font-family: Orbitron;

            &.active {
                color: #6b83ff;
            }
        }
    }

    .box-connect {
        width: calc(100% - 24px);
        position: absolute;
        bottom: 24px;
        left: 12px;
        right: 12px;
    }
`;
