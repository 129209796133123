import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { ACTION_TYPES } from './Account.action';

const initialState = {
    ilaBalance: 0,
    lockedIn: 0,
    lastDeposit: 0,
    currentPenalty: 0,
    userInfo: {},
    tierClub: {},
};

const accountReducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_ACCOUNT:
            return {
                ...state,
                userInfo: {
                    ...action.payload,
                },
            };
            break;
        case ACTION_TYPES.LOGOUT:
            return {
                ...state,
                userInfo: {},
            };
            break;
        default:
            return state;
    }
};

const persistConfig = {
    key: 'account',
    storage,
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, accountReducer);
