import { AxiosResponse } from 'axios';
import { IToken } from 'src/redux/walletAccount/WalletAccount.type';
import Web3 from 'web3';
import { ENVS } from 'src/configs/Configs.env';

export const apiGetNonceWithAddress = (address: string): Promise<AxiosResponse<IToken>> => {
    const apiUrl = `${ENVS.REACT_APP_BASE_API}auth/check-address?Address=${address}`;
    const method = 'GET';
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(apiUrl, parameters)
        .then((response) => response.json())
        .then((data) => data.result);
};

export const getSignature = async (publicAddress: string, nonce: number): Promise<string> => {
    const web3 = new Web3(window.web3.currentProvider);
    const message = `Sign in to Ordmaket\n\nChallenge: ${nonce}`;
    const encode = web3.utils.fromUtf8(message);
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        web3.eth.personal.sign(encode, publicAddress, (err, signature) => {
            if (err) return reject(err);
            return resolve(signature);
        });
    });
};
export const apiGetAccessToken = (
    address: string,
    signature: string,
    segwit: string,
    taproot: string,
    referralCode: any,
) => {
    const apiUrl = `${ENVS.REACT_APP_BASE_API}auth`;
    const method = 'POST';
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
        body: JSON.stringify({
            Address: address,
            SegwitAddress: segwit,
            TaprootAddress: taproot,
            Signature: signature,
            ReferralCode: referralCode,
        }),
    };
    return fetch(apiUrl, parameters)
        .then((response) => response.json())
        .then((data) => data.result.Token);
};
