import React, { useCallback } from 'react';
import {
    PontemWalletAdapter,
    MartianWalletAdapter,
    WalletProvider,
    AptosWalletAdapter,
    WalletError,
} from 'src/3rd/aptos';
import { useDispatch } from 'react-redux';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import { addToast } from 'src/components/Toast/redux/Toast.service';

const Provider = ({ children }: { children: any }) => {
    const dispatch = useDispatch();
    const wallets = React.useMemo(
        () => [new AptosWalletAdapter(), new MartianWalletAdapter(), new PontemWalletAdapter()],
        [],
    );

    const onError = useCallback((error: WalletError) => {
        dispatch(
            addToast({
                type: TOAST_TYPES.ERROR,
                title: 'Error!',
                description: error.message ? `${error.name}: ${error.message}` : error.name,
            }),
        );
    }, []);
    // autoConnect
    return (
        <WalletProvider wallets={wallets} onError={onError} autoConnect>
            {children}
        </WalletProvider>
    );
};

export default React.memo(Provider);
