/* eslint-disable prefer-destructuring */
import { ethers } from 'ethers';
import BIP32Factory from 'bip32';
import ecc from '@bitcoinerlab/secp256k1';
import * as bitcoin from 'bitcoinjs-lib';
import storage from 'src/utils/storage';
import { IError } from 'src/components/Provider/profile.type';

bitcoin.initEccLib(ecc);
const bip32 = BIP32Factory(ecc);

const TAPR0OT_MESSAGE =
    'This message to create your Bitcoin wallet. You will use them to send and receive NFT as well as BTC with Metamask.';

const toXOnly = (pubKey: Buffer) => (pubKey.length === 32 ? pubKey : pubKey.slice(1, 33));

const defaultPath = "m/86'/0'/0'/0/0";

const getBitcoinKeySignContent = (message: string): Buffer => {
    return Buffer.from(message);
};

const getError = (err: unknown & any): IError => {
    const randomCode = Math.floor(Math.random() * 100);
    let _err: IError;
    if (typeof err === 'string') {
        _err = {
            message: err,
            code: randomCode,
        };
    } else if (!!err && typeof err === 'object' && 'message' in err && typeof err.message === 'string') {
        const errCode =
            'code' in err && (typeof err.code === 'number' || typeof err.code === 'string') ? err.code : randomCode;
        _err = {
            message: err.message,
            code: Number(errCode),
        };
    } else {
        _err = {
            message: JSON.stringify(err || 'Oops. Something went wrong. Please try again later.'),
            code: randomCode,
        };
    }
    return _err;
};
const isAuthMetamaskError = async (error: unknown, profileAddress: string) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum as ethers.providers.ExternalProvider);
    let currentAccount;
    const accounts = await provider.send('eth_requestAccounts', []);
    if (!!accounts && !!accounts.length) {
        currentAccount = accounts[0];
        // force re-sign in
        if (!!error && !!currentAccount && currentAccount !== profileAddress) {
            const _err = getError(error);
            return _err.code === 4100;
        }
    }
    return false;
};

export const generateBitcoinTaprootKey = async (address: string) => {
    // eslint-disable-next-line no-useless-catch
    try {
        const providerGenerate = new ethers.providers.Web3Provider(
            window.ethereum as ethers.providers.ExternalProvider,
        );
        const toSign = `0x${getBitcoinKeySignContent(TAPR0OT_MESSAGE).toString('hex')}`;
        const signature = await providerGenerate.send('personal_sign', [toSign, address.toString()]);
        const seed = ethers.utils.arrayify(ethers.utils.keccak256(ethers.utils.arrayify(signature)));
        const root = bip32.fromSeed(Buffer.from(seed));
        // Taproot
        const taprootChild = root.derivePath(defaultPath);
        const { address: taprootAddress } = bitcoin.payments.p2tr({
            internalPubkey: toXOnly(taprootChild.publicKey),
        });

        if (taprootAddress) {
            storage.setUserTaprootAddress(address, taprootAddress);
        }

        return {
            root,
            taprootChild,
            address: taprootAddress,
            signature,
        };
    } catch (error) {
        const isMetamaskAuthError = await isAuthMetamaskError(error, address);
        console.log('isMetamaskAauthError: ', isMetamaskAuthError);
        // if (isMetamaskAuthError && !!store && !!store.dispatch) {
        //     await clearAuthStorage();
        //     await store.dispatch(resetUser());
        //     window.location.replace(ROUTE_PATH.WALLET);
        // }
        throw error;
    }
};
