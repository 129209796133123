import { ClipboardCopyIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import React from 'react';
import { useDispatch } from 'react-redux';
import { actionCloseModal } from 'src/components/Modal/Modal.actions';
import useCopyClipboard from 'src/hooks/useCopyClipboard';
import { ellipsisCenter } from 'src/utils/ellipsis';
import styled from 'styled-components';
import ExternalLink from 'src/components/ExternalLink';
import Typography from 'src/components/Typography';

import { BTNPrimary } from '../Button';
import PrimaryText from '../PrimaryText';

const Wrapper = styled.div`
    .title {
        font-size: 24px;
        font-weight: 500;
        color: white;
    }
    .text {
        font-size: 18px;
        font-weight: 500;
        color: #c1c1c1;
        span {
            color: white;
        }
    }

    .icon-copy {
        width: 20px;
    }

    .tx {
        display: flex;
        gap: 4px;
        align-items: center;
        cursor: pointer;
    }

    .btn-success {
        margin-top: 32px;
        font-weight: 500;
        font-size: 18px;
    }
`;

export default function NotifySuccess({
    sender,
    receiver,
    amount,
    tx,
}: {
    sender: string;
    receiver: string;
    amount: number;
    tx: string;
}) {
    const dispatch = useDispatch();
    const [isCopied, setCopied] = useCopyClipboard();

    const onBtnClick = () => {
        dispatch(actionCloseModal());
    };
    return (
        <Wrapper>
            <PrimaryText className="fw-bold disable-pointer title">Send BTC</PrimaryText>
            <div className="text">
                From:{' '}
                <span>
                    {ellipsisCenter({
                        str: sender,
                        limit: 12,
                    })}
                </span>
            </div>
            <div className="text">
                To:{' '}
                <span>
                    {' '}
                    {ellipsisCenter({
                        str: receiver,
                        limit: 12,
                    })}
                </span>
            </div>
            <div className="text">
                Amount: <span>{amount / 1e8} BTC</span>
            </div>
            <div className="text tx">
                Transaction:{' '}
                <span>
                    {' '}
                    {ellipsisCenter({
                        str: tx,
                        limit: 12,
                    })}
                </span>{' '}
                <ClipboardCopyIcon
                    className="icon-copy"
                    onClick={() => {
                        setCopied(tx);
                    }}
                />
                <ExternalLink
                    className="wallet-explorer-icon"
                    color="blue"
                    startIcon={<ExternalLinkIcon width={16} height={16} />}
                    href={`https://mempool.space/tx/${tx}`}
                >
                    <Typography variant="sm" />
                </ExternalLink>
            </div>
            <BTNPrimary className="btn-success" title="Close" onClick={onBtnClick} />
        </Wrapper>
    );
}
