import http from 'src/services/http';
import { camelCaseKeys } from 'src/utils/camelcase';
import { ENVS } from '../../configs/Configs.env';

export const apiLogin = async ({ email, password }: { email: string; password: string }) => {
    return http
        .post('auth/login', {
            Email: email,
            Password: password,
        })
        .then((res) => camelCaseKeys(res));
};

export const apiSignup = ({
    email,
    password,
    confirmPassword,
}: {
    email: string;
    password: string;
    confirmPassword: string;
}) => {
    return http
        .post('auth/register', {
            Email: email,
            Password: password,
            ConfirmPassword: confirmPassword,
        })
        .then((res) => camelCaseKeys(res));
};

export const apiVerifyEmail = ({ token }: { token: string }) => {
    return http
        .post('auth/verify-email', {
            Token: token,
        })
        .then((res) => camelCaseKeys(res));
};

export const apiReSendVerifyEmail = ({ token }: { token: string }) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/rensend-verify`;
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiForgotSubmitEmail = ({ email }: { email: string }) => {
    return http
        .post('auth/forgot', {
            Email: email,
        })
        .then((res) => camelCaseKeys(res));
};

export const apiForgotSubmitCode = ({
    code,
    newPassword,
    confirmNewPassword,
}: {
    code: string;
    newPassword: string;
    confirmNewPassword: string;
}) => {
    return http
        .post('auth/reset', {
            Token: code,
            NewPassword: newPassword,
            ConfirmNewPassword: confirmNewPassword,
        })
        .then((res) => camelCaseKeys(res));
};
