import React, { Suspense } from 'react';
import { BackTop, Image } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { IRouteProps } from 'src/modules';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { COMPONENT_IDS } from 'src/app/App.constant';
import enhance from 'src/modules/MainRoute/MainRoute.enhance';
import { IProps } from 'src/modules/MainRoute/MainRoute.inteface';
import Toast from 'src/components/Toast/Toast';
import scrollToTopImage from 'src/assets/image/scroll-to-top.png';
import { actionUpdateAccessToken } from 'src/redux/walletAccount/WalletAccount.action';
import { actionGetProfileInfo } from '../Accounts/redux/Account.action';

const Styled = styled.div<{ height: number }>`
    min-height: ${({ height }) => `${height}px`};
    // margin-top: 80px;

    .ant-back-top {
        opacity: 0.7;
    }
`;

const MainRoute = (props: IProps & any) => {
    const dispatch = useDispatch();
    const { routes } = props;
    const wallet = useSelector((state: any) => state.walletAccount);
    const { account, deactivate } = useActiveWeb3React();

    const [height, setHeight] = React.useState(0);
    const getContentHeight = () => {
        // const search = window.location.pathname.toString().search('landing-page');
        // if (search !== -1) {
        //     return;
        // }
        const headerElement = document.getElementById(COMPONENT_IDS.HEADER) as HTMLFormElement;
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const footerElement = document.getElementById(COMPONENT_IDS.FOOTER) as HTMLFormElement;
        const footerHeight = footerElement ? footerElement.clientHeight : 0;
        const windowHeight = window.innerHeight;
        setHeight(windowHeight - headerHeight - footerHeight);
    };
    React.useEffect(() => {
        if (wallet?.token && account) {
            const infoParser = JSON.parse(Buffer.from(wallet.token.split('.')[1], 'base64').toString());
            const dateExp = new Date(infoParser.exp * 1000);
            const currentDate = Date.now();
            const distanceDate = (dateExp.getTime() - currentDate) / 1000;
            if (distanceDate / 3600 < 3) {
                dispatch(
                    actionUpdateAccessToken({
                        address: '',
                        token: '',
                        nonce: 0,
                        tapRootAddress: '',
                        segWitAddress: '',
                        signature: '',
                    }),
                );
                typeof deactivate === 'function' && deactivate();
                return;
            }
            window.token = wallet.token;
            dispatch(actionGetProfileInfo(wallet?.token));
        } else {
            dispatch(
                actionUpdateAccessToken({
                    address: '',
                    token: '',
                    nonce: 0,
                    tapRootAddress: '',
                    segWitAddress: '',
                    signature: '',
                }),
            );
        }
    }, [account]);
    React.useEffect(() => {
        getContentHeight();
    }, []);

    if (!routes || routes.length === 0) return null;
    return (
        <Styled
            className="style-wrap-main"
            height={height}
            // style={{ marginTop: height === 0 ? height : isMobile ? 40 : 80 }}
        >
            {/* height === 0 ? height : 80 */}
            <Toast />
            <Switch>
                <Suspense fallback={null}>
                    {routes.map((route: IRouteProps) => (
                        <Route {...route} key={route.path} />
                    ))}
                </Suspense>
            </Switch>
            {/* <BackTop>
                <div className="scroll-to-top">
                    <Image preview={false} src={scrollToTopImage} />
                </div>
            </BackTop> */}
        </Styled>
    );
};

export default enhance(React.memo(MainRoute));
