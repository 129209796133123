import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/share-to-earn/code/:code';

const ShareToEarnRoute: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/ShareToEarn')),
    name: 'ShareToEarn',
};

export default ShareToEarnRoute;
