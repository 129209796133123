import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/collection/:collectionName/:id';

const NFTDetails: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/Collections/components/NFTDetails')),
    name: 'NFTDetails',
};

export default NFTDetails;
