import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { IAuthState } from './Auth.type';
import { ACTION_TYPES } from './Auth.constant';

const initialState: IAuthState = {
    signin: {
        email: undefined,
        password: undefined,
    },
    signup: {
        email: undefined,
        password: undefined,
        confirmPassword: undefined,
        error: undefined,
    },
    forgot: {
        email: undefined,
        code: undefined,
        pass: undefined,
        confirmPass: undefined,
        error: undefined,
    },
    userInfo: {
        auth: {
            accessToken: '',
            email: '',
        },
    },
    verify: {
        error: '',
    },
    loading: false,
};

const authReducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case ACTION_TYPES.SIGNIN: {
            return {
                ...state,
                signin: {
                    ...state.signin,
                    ...action.payload,
                },
            };
        }
        case ACTION_TYPES.SIGNUP: {
            return {
                ...state,
                signup: {
                    ...state.signup,
                    ...action.payload,
                },
            };
        }
        case ACTION_TYPES.FORGOT: {
            return {
                ...state,
                forgot: {
                    ...state.forgot,
                    ...action.payload,
                },
            };
        }
        case ACTION_TYPES.SIGNIN_SUCCESS: {
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    auth: {
                        ...action.payload,
                    },
                },
            };
        }
        case ACTION_TYPES.LOGOUT: {
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    auth: { ...initialState.userInfo.auth },
                },
            };
        }
        case ACTION_TYPES.FREE: {
            return {
                ...state,
                ...initialState.signin,
                ...initialState.signup,
                ...initialState.forgot,
            };
        }
        case ACTION_TYPES.LOADING: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case ACTION_TYPES.VERIFY: {
            return {
                ...state,
                verify: {
                    ...state.verify,
                    ...action.payload,
                },
            };
        }
        default:
            return state;
    }
};

const persistConfig = {
    key: 'authReducer',
    storage,
    whitelist: ['userInfo'],
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, authReducer);
