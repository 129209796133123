/* eslint-disable class-methods-use-this */
import { UTXO } from 'generative-sdk';
import { LocalStorageKey } from './common';

export class StorageService {
    private _getKey = (key: string) => key;

    getCustomKey(prefix: string, suffix: string) {
        return `${prefix}-${suffix}`;
    }

    set(key: string, data: unknown) {
        const _key = this._getKey(key);
        const dataStr = JSON.stringify(data);
        return localStorage.setItem(_key, dataStr);
    }

    get(key: string) {
        const _key = this._getKey(key);
        const dataStr = localStorage.getItem(_key);
        return dataStr ? JSON.parse(dataStr) : undefined;
    }

    remove(key: string) {
        const _key = this._getKey(key);
        return localStorage.removeItem(_key);
    }

    // User address taproot profile, key by web3 address
    private getUserTaprootKey = (evmAddress: string) => {
        return `${LocalStorageKey.WALLET_ADDRESS_TAPROOT}-${evmAddress}`;
    };

    getUserTaprootAddress = (evmAddress: string) => {
        const _key = this.getUserTaprootKey(evmAddress);
        return this.get(_key);
    };

    setUserTaprootAddress = (evmAddress: string, taprootAddress: string) => {
        const _key = this.getUserTaprootKey(evmAddress);
        return this.set(_key, taprootAddress);
    };

    removeUserTaprootAddress = (evmAddress: string) => {
        const _key = this.getUserTaprootKey(evmAddress);
        return this.remove(_key);
    };
}

const storage = new StorageService();

const getStorageKey = () => {
    return `storage-send-btc`;
};
const getUTXOKey = (utxo: UTXO) => {
    let txIDKey = utxo.tx_hash.concat(':');
    txIDKey = txIDKey.concat(utxo.tx_output_n.toString());
    return txIDKey;
};

export const getPendingUTXOs = (): any[] => {
    const storeKey = getStorageKey();

    const expiredTime = 60 * 60 * 1000; // 60 mins

    const utxos: any[] = JSON.parse(localStorage.getItem(storeKey) || '[]');
    const filter = utxos.filter((utxo) => {
        const { createdTime } = utxo;
        const now = new Date().getTime();
        const isExpired = now - Number(createdTime) > expiredTime;
        return !isExpired;
    });
    localStorage.setItem(storeKey, JSON.stringify(filter));
    return filter;
};
export const setPendingUTXOs = (utxos: UTXO[]) => {
    const storeKey = getStorageKey();
    const mappedUTXOs: any[] = utxos.map((utxo) => {
        const txIDKey = getUTXOKey(utxo);
        const createdTime = new Date().getTime().toString();
        return {
            ...utxo,
            txIDKey,
            createdTime,
        };
    });
    const storageData = (mappedUTXOs || []).concat(getPendingUTXOs() || []);
    localStorage.setItem(storeKey, JSON.stringify(storageData));
};
export default storage;
