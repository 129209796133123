import { debounce } from 'lodash';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCollectedUTXO, getFeeRate } from 'src/services/bitcoin';
import { getProfileByWallet } from 'src/services/profile';
import * as GENERATIVE_SDK from 'generative-sdk';
import { actionUpdateAccessToken } from 'src/redux/walletAccount/WalletAccount.action';
import { ACTION_TYPES, IToken } from 'src/redux/walletAccount/WalletAccount.type';
import {
    ICollectedUTXOResp,
    IFeeRate,
    IGetProfileTokensResponse,
    IGetProjectItemsResponse,
    User,
} from './profile.type';

const LOG_PREFIX = 'ProfileContext';

export interface IProfileContext {
    currentUser: User | null;
    profileTokens?: IGetProfileTokensResponse;
    profileProjects?: IGetProjectItemsResponse;
    collectedUTXOs?: ICollectedUTXOResp;
    feeRate: IFeeRate | undefined;
    debounceFetchCollectedUTXOs: () => void;
    debounceFetchFeeRate: () => void;
}

const initialValue: IProfileContext = {
    currentUser: null,
    feeRate: undefined,
    debounceFetchCollectedUTXOs: () => new Promise<void>((r) => r()),
    debounceFetchFeeRate: () => new Promise<void>((r) => r()),
};

export const ProfileContext = React.createContext<IProfileContext>(initialValue);

export const ProfileProvider = ({ children }: any): React.ReactElement => {
    const { tapRootAddress } = useSelector((state: any) => state.walletAccount);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [collectedUTXOs, setCollectedUTXOs] = useState<ICollectedUTXOResp | undefined>();
    const [feeRate, setFeeRate] = useState<IFeeRate | undefined>();

    const fetchCollectedUTXOs = async () => {
        try {
            // setIsLoadingUTXOs(true);
            const resp = await getCollectedUTXO(tapRootAddress);
            setCollectedUTXOs(resp as any);
        } catch (error) {
            // handle fetch data error here
        } finally {
            // setIsLoadingUTXOs(false);
        }
    };

    const debounceFetchCollectedUTXOs = debounce(fetchCollectedUTXOs, 300);

    const fetchFeeRate = async () => {
        try {
            const _feeRate = await getFeeRate();
            setFeeRate(_feeRate);
        } catch (error) {
            console.log('error: ', error);
            setFeeRate({
                fastestFee: 15,
                halfHourFee: 10,
                hourFee: 5,
            });
        }
    };

    const debounceFetchFeeRate = debounce(fetchFeeRate, 300);

    useEffect(() => {
        if (tapRootAddress) {
            debounceFetchCollectedUTXOs();
        }
    }, [tapRootAddress]);
    useEffect(() => {
        debounceFetchFeeRate();
    }, []);

    const contextValues = useMemo((): IProfileContext => {
        return {
            feeRate,
            currentUser,
            debounceFetchCollectedUTXOs,
            debounceFetchFeeRate,
            collectedUTXOs,
        };
    }, [feeRate, currentUser, collectedUTXOs]);
    return <ProfileContext.Provider value={contextValues}>{children}</ProfileContext.Provider>;
};
