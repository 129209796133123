import styled, { ITheme } from 'styled-components';

const PrimaryText = styled.p`
    letter-spacing: 0.02em;
    font-family: 'Orbitron';
    font-style: normal;
    color: ${({ theme }: { theme: ITheme }) => theme.text3};
    .title-special {
        letter-spacing: 0.02em;
        font-family: 'Orbitron';
        font-style: normal;
        color: rgb(87, 255, 103);
        margin-right: 10px;
        line-height: 70px;
    }
`;

export default PrimaryText;
