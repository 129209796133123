import http from 'src/services/http';
import { ethers, Contract } from 'ethers';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { ENVS, isMainnet } from 'src/configs/Configs.env';
import orderBy from 'lodash/orderBy';
import { Web3Provider } from '@ethersproject/providers';
import { getContract, getContractSkeleton } from 'src/functions/getContract';
import { IListHistory } from '../NFTDetails.type';
import contractSkeleton from '../../../contracts/skeleton_nft.json';
import contractMarketAbi from '../../../contracts/market.json';
import contractMarketBUSDAbi from '../../../contracts/marketBUSD.json';
import { camelCaseKeys } from '../../../utils/camelcase';

const provideUrl = isMainnet ? 'https://bsc-dataseed.binance.org/' : 'https://data-seed-prebsc-1-s3.binance.org:8545/';

export const loadListNFTs = async (
    address: string,
    contractAddressCollection: string,
    library: Web3Provider | undefined,
    account: string | null | undefined,
) => {
    if (!account || !library) {
        throw new Error('Provider or Account not found');
    }
    const contractABI = contractSkeleton.abi;
    let listData: string[] = [];
    if (!contractAddressCollection) {
        return listData;
    }
    const contract = getContract(contractAddressCollection, contractABI, library, account);

    try {
        let accountAssets = await contract.balanceOf(address);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (accountAssets) {
            accountAssets = parseInt(accountAssets.toString(), 10);
            for (let index = 0; index < accountAssets; index += 1) {
                // eslint-disable-next-line no-await-in-loop
                let indexChar = await contract.tokenOfOwnerByIndex(address, index);
                listData.push(indexChar.toString());
            }
        }
        return listData;
    } catch (error) {
        console.log('error: ', error);
    }
};

export const loadListNFTsV2 = async (address: string) => {
    const contractABI: any = contractSkeleton.abi;
    const contractAddr: any = ENVS.REACT_APP_IS_MAINNET
        ? contractSkeleton.address.mainnet
        : contractSkeleton.address.testnet;

    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(contractABI, contractAddr);

    let listData: string[] = [];
    try {
        let accountAssets = await contract.methods.balanceOf(address).call();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        accountAssets = parseInt(accountAssets.toString(), 10);
        for (let index = 0; index < accountAssets; index += 1) {
            // eslint-disable-next-line no-await-in-loop
            let indexChar = await contract.methods.tokenOfOwnerByIndex(address, index).call();
            listData.push(indexChar.toString());
        }

        return listData;
    } catch (error) {
        console.log('error: ', error);
    }
};

export const apiGetAssetsDetail = async (id: string, collectionName: string, userID?: string) => {
    let urlGetAssetsDetails = `${ENVS.REACT_APP_BASE_API}assets/${collectionName}/${id}`;
    if (userID) {
        urlGetAssetsDetails += `?user_id=${userID}`;
    }
    return http.get(urlGetAssetsDetails, { headers: { Authorization: `Bearer ${window.token}` } });
};

export const apiGetAssetsDetails = async (ids: string[]) => {
    const params = ids.join(',');
    if (params) {
        const urlGetAssetsDetails = `${ENVS.REACT_APP_BASE_API}assets/?filter[inscription_id.in]=${params}`;
        return http.get(urlGetAssetsDetails, { headers: { Authorization: `Bearer ${window.token}` } });
    }
    return [];
};

export const apiGetTradeVolume = async (collectionName: any) => {
    let urlTradeVolume = `${ENVS.REACT_APP_BASE_API}stast`;
    if (collectionName) {
        urlTradeVolume += `/${collectionName}`;
    }
    return http.get(urlTradeVolume);
};

export const apiGetListNft = async (page: number, limit: number, params: string[]) => {
    let urlGetAssets = `${ENVS.REACT_APP_BASE_API}assets/`;
    if (params.length > 0) {
        urlGetAssets = `${urlGetAssets}?${params.join('&')}`;
    }
    return http.get(urlGetAssets, {
        headers: { Authorization: `Bearer ${window.token}` },
        params: { page, limit },
    });
};

export const apiGetNFTsFavorites = async (page: number, limit: number, params: string[]) => {
    let urlGetAssets = `${ENVS.REACT_APP_BASE_API}favorites/my-nft-favorites`;
    if (params.length > 0) {
        urlGetAssets = `${urlGetAssets}?${params.join('&')}`;
    }
    return http.get(urlGetAssets, {
        headers: { Authorization: `Bearer ${window.token}` },
        params: { page, limit },
    });
};

export const apiGetActivities = async (page: number, limit: number, params: string) => {
    let urlGetActivities = `${ENVS.REACT_APP_BASE_API}activity`;
    if (params) {
        urlGetActivities = `${urlGetActivities}?${params}`;
    }
    return http.get(urlGetActivities, {
        headers: { Authorization: `Bearer ${window.token}` },
        params: { page, limit },
    });
};

export const apiGetListCollections = async (name?: string) => {
    let urlGetAssets = `${ENVS.REACT_APP_BASE_API}collections`;
    if (name) {
        urlGetAssets += `?filter[collection_seo_name]=${name}`;
    }
    try {
        return http.get(urlGetAssets, {
            headers: { Authorization: `Bearer ${window.token}` },
        });
    } catch (error) {
        console.log('error: ', error);
    }
};

export const apiGetListCollectionBySeoName = async (seoName: string) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}collections/?filter[collection_seo_name]=${seoName}`;
    return http.get(apiURL, {
        headers: { Authorization: `Bearer ${window.token}` },
    });
};

export const apiGetPoolApprovedByID = async (poolID: any, poolType?: number, poolStatus?: number) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}pool-approved/?filter[social_url.in]=${poolID}`;
    if (poolType) {
        apiURL += `&filter[pool_type]=${poolType}`;
    }
    if (poolStatus) {
        apiURL += `&filter[status]=${poolStatus}`;
    }
    const parameters = {
        method: 'GET',
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetInoBySlug = async (slug: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}ino?filter[slug]=${slug}`;
    const parameters = {
        method: 'GET',
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const apiGetInoByStatus = async (status: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}ino?filter[status]=${status}`;
    const parameters = {
        method: 'GET',
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetHistoryList = async (
    nftId: string,
    collectionId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    type = 'list',
): Promise<IListHistory[]> => {
    // const urlHistory = `${ENVS.REACT_APP_BASE_API}activity/${type}?filter[inscription_id]=${nftId}&filter[collection_id]=${collectionId}`;
    const urlHistory = `${ENVS.REACT_APP_BASE_API}activity/?filter[inscription_id]=${nftId}&filter[collection_id]=${collectionId}`;
    const res = (await http.get(urlHistory)) as any;
    if (res.Items) {
        return orderBy(camelCaseKeys(res.Items || []), 'createdAt', 'desc');
    }
    return [];
};
export const apiUpdateFavorites = async (nftUid: any, favorite: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}favorites/add`;
    if (favorite) {
        apiURL = `${ENVS.REACT_APP_BASE_API}favorites/remove`;
    }
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
        body: JSON.stringify({ ID: nftUid }),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const apiGetCollectionsList = async (type: any, page: number) => {
    const filter: any = {
        art: 1,
        gaming: 2,
        memberships: 3,
        pfps: 4,
        photography: 4,
    };
    let urlGetAssets = `${ENVS.REACT_APP_BASE_API}collections?filter[status]=2`;
    if (filter[type]) {
        urlGetAssets += `&filter[type]=${filter[type]}&page=${page}&limit=20`;
    } else {
        urlGetAssets += `&page=${page}&limit=20`;
    }
    urlGetAssets += `&filter[created_at.sort]=desc`;

    return http.get(urlGetAssets, {
        headers: { Authorization: `Bearer ${window.token}` },
    });
};

export const apiSearchCollections = async (keyWork: any) => {
    let urlGetAssets = `${ENVS.REACT_APP_BASE_API}collections`;
    if (!keyWork) {
        return [];
    }
    urlGetAssets += `?filter[collection_name.like]=${keyWork}`;
    return http.get(urlGetAssets, {
        headers: { Authorization: `Bearer ${window.token}` },
    });
};

export const apiGetSettings = async (name: string) => {
    let url = `${ENVS.REACT_APP_BASE_API}settings/${name}?filter[status]=1`;
    return http.get(url, {
        headers: { Authorization: `Bearer ${window.token}` },
    });
};

export const approveBNB = async (
    contractAddr: any,
    contractMarketAddr: any,
    library: Web3Provider | undefined,
    account: string | null | undefined,
) => {
    if (!account || !library) {
        throw new Error('Provider or Account not found');
    }
    try {
        const contractABI = contractSkeleton.abi;
        const skeletonContract = getContract(contractAddr, contractABI, library, account);

        const tx = await skeletonContract.setApprovalForAll(contractMarketAddr, true);
        return tx;
    } catch (error) {
        console.log('approveBNB: ', error);
        throw error;
    }
};
export const buyNft = async (
    nftId: string,
    price: any,
    contractMarketAddr: string,
    contractRouter: string,
    isNftIdo: boolean,
    library: Web3Provider | undefined,
    account: string | null | undefined,
) => {
    if (!account || !library) {
        throw new Error('Provider or Account not found');
    }
    const contractABI: any = isNftIdo ? contractMarketBUSDAbi : contractMarketAbi;
    const contractAddress = isNftIdo ? contractMarketAddr : contractRouter;
    const contract = getContract(contractAddress, contractABI, library, account);
    try {
        if (isNftIdo) {
            return await contract.buyNft(nftId);
        }
        return await contract.buyNft(contractMarketAddr, nftId, { value: ethers.utils.parseEther(price) });
    } catch (error) {
        console.log('buyNft: ', error);
        throw error;
    }
};
export const getApprove = async (address: string, contractAddr: string, contractMarketAddr: string) => {
    const contract = getContractSkeleton(contractAddr);
    try {
        const result = await contract.methods.isApprovedForAll(address, contractMarketAddr).call();
        return result;
    } catch (error) {
        console.log('getApprove: ', error);
        throw error;
    }
};
export const getOwnerOfToken = async ({ nftID, contractAddress }: { nftID: string; contractAddress: string }) => {
    const contract = getContractSkeleton(contractAddress);
    try {
        return contract.methods.ownerOf(nftID).call();
    } catch (error) {
        console.log('getOwnerOfToken error: ', error);
    }
};
export const getTokenPrice = async (
    nftId: string,
    contractMarketAddress: string,
    contractRouter: string,
    isNftIdo: boolean,
) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contractABI: any = isNftIdo ? contractMarketBUSDAbi : contractMarketAbi;
    const contract: any = new web3.eth.Contract(contractABI, isNftIdo ? contractMarketAddress : contractRouter);
    try {
        if (isNftIdo) {
            const result = await contract.methods.getPriceByTokenId(nftId).call();
            return new BigNumber(result.toString()).div(10 ** 18).toNumber();
        }
        const resultF = await contract.methods.getPriceByTokenId(contractMarketAddress, nftId).call();
        return new BigNumber(resultF.toString()).div(10 ** 18).toNumber();
    } catch (error) {
        console.log('error: ', error);
        throw error;
    }
};
export const delistNft = async (
    nftId: string,
    contractMarketAddr: string,
    contractRouter: string,
    isNftIdo: boolean,
    library: Web3Provider | undefined,
    account: string | null | undefined,
) => {
    if (!account || !library) {
        throw new Error('Provider or Account not found');
    }
    const contractABI: any = isNftIdo ? contractMarketBUSDAbi : contractMarketAbi;
    const contractAddress = isNftIdo ? contractMarketAddr : contractRouter;
    const contract = getContract(contractAddress, contractABI, library, account);

    try {
        let tx: any = null;
        if (isNftIdo) {
            tx = await contract.delistNft(nftId);
        } else {
            tx = await contract.delistNft(contractMarketAddr, nftId);
        }
        return tx;
    } catch (error) {
        console.log('delistNft: ', error);
        throw error;
    }
};
export const listNft = async (
    nftId: string,
    price: number,
    contractMarketAddr: string,
    contractRouter: string,
    isNftIdo: any,
    library: Web3Provider | undefined,
    account: string | null | undefined,
) => {
    if (!account || !library) {
        throw new Error('Provider or Account not found');
    }
    const contractABI: any = isNftIdo ? contractMarketBUSDAbi : contractMarketAbi;
    const contractAddress = isNftIdo ? contractMarketAddr : contractRouter;
    const contract = getContract(contractAddress, contractABI, library, account);

    try {
        if (isNftIdo) {
            return await contract.listNft(nftId, ethers.utils.parseEther(price.toString()));
        }
        return await contract.listNft(contractMarketAddr, nftId, ethers.utils.parseEther(price.toString()));
    } catch (error) {
        console.log('listNft error: ', error);
        throw error;
    }
};
