import React, { memo } from 'react';
import { Col, Image, Row } from 'antd';
import styled, { ITheme } from 'styled-components';
import { ReactComponent as IcTelegram } from 'src/telegram.svg';
import { ReactComponent as IcMedium } from 'src/medium.svg';
import { ReactComponent as IcGitBook } from 'src/gitbook.svg';
import { ReactComponent as IcDiscord } from 'src/discord.svg';
import { ReactComponent as IcTwitter } from 'src/twitter.svg';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { FooterFrame } from './Footer.styled';

export interface ISocial {
    image: any;
    link: string;
}

export const socials: ISocial[] = [
    {
        image: <IcTwitter />,
        link: 'https://twitter.com/ordmarket',
    },
    // {
    //     image: <IcMedium />,
    //     link: '#',
    // },
    {
        image: <IcDiscord />,
        link: 'https://discord.gg/ee2j3SRNKU',
    },
    {
        image: <IcGitBook />,
        link: 'https://docs.ordmarket.io',
    },
];

export const FooterWrap = styled.section`
    max-width: 1170px;
    width: 100%;
    margin: auto;
    .footer-sub-menu {
        font-family: 'Jost';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
    }
    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        margin: auto;
        display: flex;
        justify-content: center;
        text-align: center;
        font-family: 'Orbitron';
        color: #000000;
    }
    .learn-more {
        padding: 90px 0;
    }
    .ant-image {
        margin: auto;
        .ant-image-img {
            width: 70%;
            margin: auto;
        }
    }
    .line-break {
        background: linear-gradient(
            90deg,
            rgba(70, 194, 82, 0.08) 0%,
            rgba(70, 194, 82, 0) 0.01%,
            #6b83ff 52.08%,
            rgba(70, 194, 82, 0) 100%
        );
        height: 1px;
        width: 100%;
        border-radius: 50px;
    }
    .learn-more {
        .title {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            text-align: center;
            font-size: 36px;
            color: #fff;
        }
        .description {
            margin-top: 16px;
            display: flex;
            justify-content: center;
            text-align: center;
            color: ${({ theme }: { theme: ITheme }) => theme.text1};
            font-size: 16px;
        }

        .social-media {
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                width: 60px;
                display: flex;
                text-align: center;
                justify-content: center;
                .ant-image {
                    width: 25px;
                }
            }
        }
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        .media-kit{
            .text{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                color: #fff;
                padding-bottom: 30px;
            }
        }
        .learn-more {
            padding: 70px 0 !important;
        }
        .copy-right span{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            text-align: center;
            color: #fff;
        }
        .title{
            font-size: 26px !important;
        }
        .description{
            font-size: 14px !important;
        }
        .footer-wrap .ant-image .ant-image-img{
            width: 100%;
            margin-top: 25px;
        }
        .footer-wrap .learn-more {
            padding: 40px 0;
        }
        `}
`;

const Footer = () => {
    return (
        <FooterWrap>
            <div className="body">
                <div className="line-break" style={{ marginTop: 150 }} />
                <div className="learn-more">
                    <div className="title fw-supermedium">Want to learn more?</div>
                    <div className="description fs-small">
                        Join our community to discuss the lastest news and happenings on OrdMarket.IO
                    </div>
                    <div className="social-media">
                        {socials.map((item) => {
                            return (
                                <a target="_blank" key={item.link} href={item.link} rel="noreferrer">
                                    {item.image}
                                </a>
                            );
                        })}
                    </div>
                </div>
                <div className="line-break" style={{ marginBottom: 50 }} />
            </div>
        </FooterWrap>
    );
};

export default memo(Footer);
