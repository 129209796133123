import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { SUPPORTED_WALLETS } from 'src/configs/Connect/Configs.wallets';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { SUPPORTED_NETWORKS } from 'src/configs/Connect/Configs.networks';
import { ACTION_TYPES } from 'src/components/Header/redux/Wallet.action';
import { actionSignIn, actionUpdateAccessToken } from 'src/redux/walletAccount/WalletAccount.action';
import cloneDeep from 'lodash/cloneDeep';
import { ChainId } from 'src/configs/Connect/Configs.chainId';

const withConnect = (WrappedComponent: FunctionComponent) => (props: any) => {
    const { activate } = useWeb3React();
    const { library, account, deactivate, chainId } = useActiveWeb3React();
    const network = useSelector((state: any) => state.wallet);
    const wallet = useSelector((state: any) => state.walletAccount);
    const dispatch = useDispatch();
    const tryActivation = async (_connector: any) => {
        dispatch({
            type: ACTION_TYPES.SET_CONNECT_WALLET,
            payload: true,
        });
        let name = '';
        let conn = typeof _connector === 'function' ? await _connector() : _connector;

        Object.keys(SUPPORTED_WALLETS).map((key) => {
            if (_connector === SUPPORTED_WALLETS[key].connector) {
                name = SUPPORTED_WALLETS[key].name;
                return name;
            }
            return true;
        });
        if (conn.walletConnectProvider?.wc?.uri) {
            conn.walletConnectProvider = undefined;
        }

        conn &&
            activate(conn, undefined, true).catch((error: any) => {
                if (error instanceof UnsupportedChainIdError) {
                    activate(conn); // a little janky...can't use setError because the connector isn't set
                } else {
                    // setPendingError(true);
                }
            });
    };

    const changeChainId = async (chainNumb: number) => {
        if (chainNumb === ChainId.MAINNET) {
            library?.send('wallet_switchEthereumChain', [{ chainId: '0x1' }, account]);
        } else {
            const params = cloneDeep(SUPPORTED_NETWORKS[chainNumb]);
            delete params?.chainNumb;
            delete params?.path;
            delete params?.pathDisable;
            library?.send('wallet_addEthereumChain', [params, account]);
        }
    };

    useEffect(() => {
        if (network?.isConnectWallet && account && !wallet.tapRootAddress && chainId === 1) {
            dispatch(actionSignIn(account as string));
            dispatch({
                type: ACTION_TYPES.SET_CONNECT_WALLET,
                payload: false,
            });
        } else if (account && !wallet.tapRootAddress) {
            typeof deactivate === 'function' && deactivate();
            localStorage.removeItem('connector');
            dispatch(
                actionUpdateAccessToken({
                    address: '',
                    token: '',
                    nonce: 0,
                    tapRootAddress: '',
                    segWitAddress: '',
                    signature: '',
                }),
            );
            dispatch({
                type: ACTION_TYPES.SET_CHAIN_ID,
                payload: -1,
            });
        }
    }, [account]);
    return (
        <WrappedComponent
            {...{
                ...props,
                tryActivation,
                changeChainId,
            }}
        />
    );
};

export default withConnect;
