import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Row as AntRow } from 'antd';
import { removeToast } from './redux/Toast.service';
import { Wrapper } from './Toast.styled';
import imgCross from '../../assets/image/cross.png';

const Toast = () => {
    const dispatch = useDispatch();
    const { toasts } = useSelector((state: any) => state.toast);

    useEffect(() => {
        if (toasts.length === 0) {
            return;
        }
        const interval = setInterval(() => {
            dispatch(removeToast(toasts[0].id));
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    });

    return (
        <>
            <Wrapper>
                {toasts.map((toast: any) => {
                    return (
                        <div key={`toast${toast.id}`} className="notification">
                            <AntRow>
                                <p className={`fs-medium fw-bold ${toast.type ?? 'success'}`}>{toast.title}</p>
                                <Image
                                    className="close-button"
                                    preview={false}
                                    src={imgCross}
                                    onClick={() => dispatch(removeToast(toast.id))}
                                />
                            </AntRow>
                            <AntRow>
                                <p className="fs-small fw-medium description">{toast.description}</p>
                            </AntRow>
                        </div>
                    );
                })}
            </Wrapper>
        </>
    );
};

export default Toast;
