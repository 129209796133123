/* eslint-disable prefer-destructuring */
import React, { useContext, useState, useEffect } from 'react';
import * as GENERATIVE_SDK from 'generative-sdk';
import { debounce } from 'lodash';
import {
    ICollectedUTXOResp,
    ISendBTCProps,
    TrackTxType,
    ISendInsProps,
    ISignKeyResp,
} from 'src/components/Provider/profile.type';
import { ProfileContext } from 'src/components/Provider/profile-context';
import { useDispatch, useSelector } from 'react-redux';
import { trackTx, broadcastTx, submitTxs } from 'src/services/bitcoin';

import { AssetsContext } from 'src/components/Provider/asset-context';
import SDK from 'src/utils/sdk';
import { actionToggleModal } from 'src/components/Modal/Modal.actions';
import NotifySuccess from 'src/components/SendBtcModal/NotifySuccess';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import { generateBitcoinTaprootKey } from './connect.methods';

const sleep = (second: number) => {
    return new Promise((resolve) => setTimeout(resolve, second * 1000));
};

export const useBitcoin = () => {
    const [satoshiAmount, setAmount] = useState(0);
    const dispatch = useDispatch();
    const { collectedUTXOs } = useContext(ProfileContext);
    const { address, tapRootAddress } = useSelector((state: any) => state.walletAccount);
    const { currentAssets, getAvailableAssetsCreateTx } = useContext(AssetsContext);

    const getBalance = (_collectedUTXOs: ICollectedUTXOResp | undefined) => {
        if (!_collectedUTXOs) return setAmount(0);
        const amount = GENERATIVE_SDK.getBTCBalance({
            utxos: _collectedUTXOs.txrefs, // []
            inscriptions: SDK.formatInscriptions(_collectedUTXOs.inscriptions_by_outputs as any), // {}
        }).toNumber();
        setAmount(amount);
    };
    const debounceGetBalance = debounce(getBalance, 300);

    const signKey = async (): Promise<ISignKeyResp> => {
        const error = 'Can not sign with metamask';
        const evmAddress = address;
        const tpAddress = tapRootAddress;
        if (!evmAddress || !tpAddress) throw new Error(error);
        const { taprootChild } = await generateBitcoinTaprootKey(evmAddress);
        const privateKey = taprootChild.privateKey;
        if (!privateKey) throw new Error(error);
        return {
            privateKey,
            tpAddress,
            evmAddress,
        };
    };

    const sendInscription = async ({ receiverAddress, feeRate, inscriptionNumber, inscriptionID }: ISendInsProps) => {
        if (!inscriptionID || !receiverAddress) return;
        const assets = await getAvailableAssetsCreateTx();
        const { privateKey, tpAddress } = await signKey();
        if (!assets) throw new Error('Can not load assets');
        console.log({
            privateKey,
            utxos: assets.txrefs,
            inscriptions: assets.inscriptions_by_outputs,
            inscriptionID,
            receiver: receiverAddress,
            feeRate,
        });
        const { txID, txHex } = await SDK.sendInsTransaction({
            privateKey,
            utxos: assets.txrefs,
            inscriptions: assets.inscriptions_by_outputs,
            inscriptionID,
            receiver: receiverAddress,
            feeRate,
        });
        await trackTx({
            txhash: txID,
            address: tpAddress,
            receiver: receiverAddress,
            inscription_id: inscriptionID,
            inscription_number: inscriptionNumber,
            send_amount: 0,
            type: TrackTxType.inscription,
        });
        // broadcast tx
        await submitTxs({
            [txID]: txHex,
        });

        return txID;
    };

    const sendBitcoin = async ({ receiverAddress, feeRate, amount }: ISendBTCProps) => {
        const assets = currentAssets;
        if (!assets) throw new Error('Can not load assets');
        // const { privateKey, tpAddress } = await signKey();

        if (!address || !collectedUTXOs || !tapRootAddress) return;
        try {
            // bc1psmeryjphdxlfu9x2emrzwdqjhxydl8gunhtesvfn6k29f5eezm2srgprcf
            const { taprootChild } = await generateBitcoinTaprootKey(address);
            const privateKey = taprootChild.privateKey;
            if (!privateKey) throw Error('Sign error');
            const { txID, txHex } = await SDK.sendBTCTransaction({
                privateKey,
                utxos: collectedUTXOs.txrefs,
                inscriptions: assets.inscriptions_by_outputs,
                receiver: receiverAddress,
                feeRate: feeRate * 1e5,
                amount,
            });
            console.log(txID, txHex);

            await broadcastTx(txHex);

            await trackTx({
                txhash: txID,
                address: tapRootAddress,
                receiver: receiverAddress,
                inscription_id: '',
                inscription_number: 0,
                send_amount: amount,
                type: TrackTxType.normal,
            });
            await sleep(1);
            // await submitTxs({
            //     [txID]: txHex,
            // });
            dispatch(
                actionToggleModal({
                    visible: true,
                    width: 700,
                    title: 'Send BTC Success',
                    data: (
                        <NotifySuccess tx={txID} receiver={receiverAddress} amount={amount} sender={tapRootAddress} />
                    ),
                }),
            );
        } catch (error: any) {
            console.log('error: ', error);
            dispatch(
                addToast({
                    type: TOAST_TYPES.ERROR,
                    title: 'Error!',
                    description: error.message ? `${error.name}: ${error.message}` : error.name,
                }),
            );
        }
    };

    useEffect(() => {
        debounceGetBalance(collectedUTXOs);
    }, [collectedUTXOs]);
    return { satoshiAmount, sendBitcoin, sendInscription };
};
