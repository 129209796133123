import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { getContract } from 'src/functions/getContract';
import { Web3Provider } from '@ethersproject/providers';
import { ENVS } from '../../../configs/Configs.env';

declare global {
    interface Window {
        ethereum: any;
    }
}
const provideUrl = ENVS.REACT_APP_IS_MAINNET
    ? 'https://bsc-dataseed.binance.org/'
    : 'https://data-seed-prebsc-1-s2.binance.org:8545/';

export const apiGetProfileInfo = async (token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/profile`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetReferralUser = async () => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/referral`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetProfileByAddress = async (address: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/profile/wallet/${address}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiUpdateAccount = async (data: any, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/update`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetStatusKYCFromBlockPass = (kycRefId: string) => {
    const apiKey = ENVS.REACT_APP_BLOCK_PASS_API_KEY;
    const apiURL = `https://kyc.blockpass.org/kyc/1.0/connect/${ENVS.REACT_APP_BLOCK_PASS_CLIENT_ID}/refId/${kycRefId}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${apiKey}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiUpdateOtherWallet = async (data: { Wallets: any }, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/update`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
